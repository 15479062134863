import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormSection } from '../../shared/models/form.config';
import {
  TableActionConfig,
  TableColumnConfigurable,
} from '../modals/table.modal';
import { UiStore } from './ui.store';
import { AlertService } from '../services/alert.service';
import { runInAction } from 'mobx';
import { StatusDetailsDTO } from '../../shared/dto/status.dto';
import { Observable, catchError, forkJoin, tap } from 'rxjs';
import {
  StatusDetailsLatestDTO,
  StatusHistoryDTO,
} from '../../shared/dto/status-update.dto';
import { IStatusService } from '../interfaces/IStatusService.interface';
import { StatusUpdateConversion } from '../../shared/conversion/status-update.conversion.dto';
import { SubCRUDServiceInterface } from '../interfaces/service/sub-crud-service.interface';

@Injectable({ providedIn: 'root' })
export abstract class DetailsStatusModalBaseStore {
  //generic
  isInitialLoading: boolean = false; //for showing main loader during first itme
  isFetching: boolean = false; // fetching form data
  showModal: boolean;
  protected abstract mainId: number | undefined; //main id such as compliance id/incident id etc
  protected abstract statusTitle: string; //status type/  aciton plan status, compliance status
  protected statusFormConfig: any; //for updating status dropdown options

  protected abstract openModal(id?: number): void;
  protected abstract resetEntireState(): void;

  //sub item
  protected abstract subId: number | undefined; //sub item id such as requriement id, action plan id
  subItem: any; //only incase of sub items such as requirement/action plan etc
  protected abstract convertResToSubItemDetailsDTO(response: any): any; //convert response in to sub item

  //latest status
  statusDetailsLatest: StatusDetailsLatestDTO | null;
  protected abstract convertResToStatusLatestDetailsDTO(
    response: any
  ): StatusDetailsLatestDTO; //convert res in to latest details DTO & to be defined in child class

  // Form related properties
  isShowStatusForm: boolean = false;
  formGroup = new FormGroup({});
  statuses: StatusDetailsDTO[]; //master status list for dropdown
  protected abstract formSections: FormSection[]; //form fields to be assigned on child class
  protected abstract statusHistoryId: number | undefined; //for updating status updates
  protected abstract convertFormToUpdateStatusReq(): any; //convert form in to update status request body details
  protected abstract refreshDetails(): any; //refresh page details after updating the status.

  // History related properties
  isLoading: boolean = false; //loading history table data
  history: StatusHistoryDTO[];
  isShowHistory: boolean = false;
  actionConfig: TableActionConfig = {
    isEditable: true,
    isDeletable: false,
    isActivatable: false,
  };
  getFileDownloadBaseUrl(){}
  protected abstract coloums: TableColumnConfigurable[];

  constructor(
    protected uiStore: UiStore,
    protected alertService: AlertService,
    protected statusUpdateConversion: StatusUpdateConversion, //for status update services
    protected statusUpdateService: IStatusService, //for status update services
    protected statusService: any, //for masters
    protected subService?: SubCRUDServiceInterface // this is to fethc sub details but optional
  ) {}

  fetchSubItemDetails(): void {
    this.isInitialLoading = true;
    if (this.mainId && this.subId && this.subService) {
      this.subService.get(this.mainId, this.subId).subscribe({
        next: (response: any) => {
          runInAction(() => {
            this.subItem = this.convertResToSubItemDetailsDTO(response);
            this.isInitialLoading = false;
          });
        },
        error: (error: any) => {
          console.error('Failed to fetch details:', error);
          runInAction(() => {
            this.isInitialLoading = false;
          });
        },
      });
    } else {
      throw new Error('Compliance id and requirement id not available');
    }
  }

  fetchLatestStatus() {
    if (!this.mainId) {
      console.error("Main ID hasn't been set!");
      return;
    }

    const serviceCall = this.subId
      ? this.statusUpdateService.getLatestStatus(this.mainId, this.subId)
      : this.statusUpdateService.getLatestStatus(this.mainId);

    serviceCall.subscribe({
      next: (response: any) => {
        runInAction(() => {
          this.statusDetailsLatest =
            this.convertResToStatusLatestDetailsDTO(response);
        });
      },
      error: (error: any) => {
        console.error('Error fetching latest status:', error);
      },
    });
  }

  fetchMasters(): void {
    forkJoin({
      statusMasters: this.statusService.list() as Observable<any[]>,
    })
      .pipe(
        tap(({ statusMasters }) => {
          runInAction(() => {
            this.statuses = statusMasters.map((item: any) =>
              this.statusUpdateConversion.resToStatusDetailsDTO(item)
            );

            this.updateFormOptions();
          });
        }),
        catchError((error) => {
          console.error('Error fetching master lists', error);
          throw error;
        })
      )
      .subscribe();
  }

  updateFormOptions() {
    this.statusFormConfig.updateOptions(this.formSections, this.statuses);
  }

  fetchHistory() {
    if (!this.mainId) {
      console.error("Main ID hasn't been set!");
      return;
    }

    const serviceCall = this.subId
      ? this.statusUpdateService.listStatusUpdatesHistory(
          this.mainId,
          this.subId
        )
      : this.statusUpdateService.listStatusUpdatesHistory(this.mainId);

    serviceCall.subscribe({
      next: (response: any) => {
        this.history = response.map((item: any) =>
          this.statusUpdateConversion.resToStatusHistoryDTO(
            item,
            this.statusTitle
          )
        );
        this.getFileDownloadBaseUrl();
      },
      error: (error: any) => {
        console.error('Error fetching compliance status history:', error);
      },
    });
  }

  updateStatus() {
    this.uiStore.startSaving();

    if (!this.mainId) {
      console.error('Main ID hasnt been set!');
      return;
    }
    if (!this.formGroup.valid) {
      this.uiStore.finishSaving();
      return;
    }

    const formData = this.convertFormToUpdateStatusReq();

    let serviceCall;
    if (this.subId) {
      serviceCall = this.statusHistoryId
        ? this.statusUpdateService.updateStatusUpdateByHistoryId(
            this.mainId,
            this.statusHistoryId,
            formData,
            this.subId
          )
        : this.statusUpdateService.createStatusUpdate(
            this.mainId,
            formData,
            this.subId
          );
    } else {
      serviceCall = this.statusHistoryId
        ? this.statusUpdateService.updateStatusUpdateByHistoryId(
            this.mainId,
            this.statusHistoryId,
            formData
          )
        : this.statusUpdateService.createStatusUpdate(this.mainId, formData);
    }

    serviceCall.subscribe({
      next: () => {
        runInAction(() => {
          this.alertService.success('updated', 'Update Successful');
          this.uiStore.finishSaving();
          this.refreshDetails();
          this.fetchLatestStatus();
          this.fetchHistory();
          this.resetForm();
        });
      },
      error: (error: any) => {
        this.uiStore.finishSaving();
        console.error(`Failed to update status:`, error);
      },
    });
  }

  closeModal() {
    runInAction(() => {
      this.showModal = false;
      this.closeStatusForm();
      this.resetEntireState();
    });
  }

  resetForm() {
    this.uiStore.formErrors = [];
    this.formGroup.reset();
  }

  toggleHistory() {
    runInAction(() => {
      this.isShowHistory = !this.isShowHistory;
    });
  }

  toggleStatusForm() {
    runInAction(() => {
      if (this.isShowStatusForm) {
        this.closeStatusForm();
      } else {
        this.openStatusForm();
      }
    });
  }

  openStatusForm() {
    runInAction(() => {
      this.isShowStatusForm = true;
    });
  }

  closeStatusForm() {
    runInAction(() => {
      this.resetForm();
      this.isShowStatusForm = false;
    });
  }
}
